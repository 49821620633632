import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import {OrderDetail} from '../../../constants/propTypesDefinitions';
import {createFetchOrderDispatch} from '../../../backend/apiCalls';
import {Trans, t} from '@lingui/macro';
import withDataHOC from '../../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../../constants/globalData';
import { isOrderFinished } from '../../../lib/project';
import ButtonFetch from '../../fetch/ButtonFetch';
/**
 * @fero
 */

class DispatchOrder extends React.PureComponent {
    static propTypes = {
        orderDetails: OrderDetail.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const {orderDetails, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        const disabled = isOrderFinished(orderDetails);
        return <ButtonFetch
            values={{}}
            disabled={disabled}
            Failed={generalFailedPC(t`Nepodarilo sa spracovať objednávku.`)}
            fetchFunction={createFetchOrderDispatch(orderDetails.id)}
            onFinish={() => {
                reload([GLOBAL_DATA.ORDER_DETAILS])
            }}
        >
            <Trans>Vyskladniť</Trans>
        </ButtonFetch>;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(DispatchOrder);