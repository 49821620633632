import React from 'react';
import generalFailedPC from '../../fetch/generalFailedPC';
import Tooltip from '../../general/Tooltip';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {OrderDetail} from '../../../constants/propTypesDefinitions';
import {createFetchOrderSplit} from '../../../backend/apiCalls';
import {Button, Icon} from 'antd';
import {Trans, t} from '@lingui/macro';
import { isOrderFinished } from '../../../lib/project';
import { navigateToParametrized } from '../../../lib/url';
import { QUERY_PARAMS, ROUTES } from '../../../constants/navigation';
import locationHOC from '../../locationProvider/locationHOC';
/**
 * @dusan
 */

class SplitOrder extends React.PureComponent {
    static propTypes = {
        orderDetails: OrderDetail.isRequired,
    };

    render() {
        const {orderDetails, location} = this.props;
        const disabled = isOrderFinished(orderDetails);
        return <FormModal
            disabled={disabled}
            openNode={
                <Tooltip title={<Trans>Rozdeliť objednávku</Trans>}>
                    <Button disabled={disabled}>
                        <Icon type="fork" className="mr-2"/>
                        <Trans>Rozdeliť</Trans>
                    </Button>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={(newOrderId) => {
                navigateToParametrized(location, ROUTES.ORDER_DETAILS, {[QUERY_PARAMS.ID_ORDER]: newOrderId});
            }}
            title={
                <span>
                    <Trans>Rozdeliť objednávku</Trans>
                    {' '}
                    {orderDetails.dealer_reference}
                </span>
            }
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete oddeliť neskladové položky do novej objednávky?</Trans>,
                createFetchOrderSplit(orderDetails.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa rozdeliť objednávku.`)}
        />;
    }

}

export default locationHOC(SplitOrder);