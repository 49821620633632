import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../navigation/Link';
import locationHOC from '../../locationProvider/locationHOC';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import {RIGHTS} from '../../../constants/Rights';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {OrderDetail} from '../../../constants/propTypesDefinitions';
import {isTradeSell, getBackgroundStyle} from '../../../lib/project';
import {Trans, t} from '@lingui/macro';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';

/**
 * @fero
 */

class OrderDetailsHeader extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        orderDetails: OrderDetail.isRequired,
    };

    render() {
        const {location, orderDetails = {}} = this.props;
        const isSelling = isTradeSell(orderDetails.trade);
        const isInternal = isSelling && orderDetails.id_dst_stock != null;
        const isReservation = isInternal && Array.isArray(orderDetails.id_stock) && orderDetails.id_stock.includes(orderDetails.id_dst_stock);
        const isProxy = orderDetails.final_order != null;
        return <div>
            <h2 className={'text-center py-3 ' + getBackgroundStyle(orderDetails.trade)}>
                <RightsWrapper from={RIGHTS.MARKETING}>
                    {
                        isSelling ? 
                            (
                                isInternal ? 
                                    (
                                        isReservation ? 
                                            <Trans>Detail rezervačnej objednávky č.</Trans> 
                                            : 
                                            <Trans>Detail konsignačnej objednávky č.</Trans>
                                    ) 
                                    : 
                                    (  
                                        isProxy ? 
                                            <Trans>Detail prijatej proxy objednávky č.</Trans>
                                            :
                                            <Trans>Detail prijatej objednávky č.</Trans>
                                    )
                            ) 
                            :
                            (
                                isProxy ?
                                    <Trans>Detail odoslanej proxy objednávky č.</Trans>
                                    :
                                    <Trans>Detail odoslanej objednávky č.</Trans>
                            )
                    }
                </RightsWrapper>
                <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                    { isProxy ? 
                        <Trans>Detail proxy objednávky č.</Trans>
                        : 
                        <Trans>Detail objednávky č.</Trans>
                    }
                </RightsWrapper>
                {' '}
                {orderDetails.dealer_reference}
                {' ('}
                {orderDetails.status}
                { ')'}
            </h2>
            {
                orderDetails.id_quotation != null ?
                    <h4 className="text-center py-3">
                        <Trans>Cenová ponuka č.</Trans>
                        {' '}
                        <Link
                            location={location}
                            to={ROUTES.QUOTATION_DETAILS}
                            queryParams={{[QUERY_PARAMS.ID_QUOTATION]: orderDetails.id_quotation}}
                        >
                            {orderDetails.quotation_reference}
                            {orderDetails.quotation_customer_ref != null ? ' (' + orderDetails.quotation_customer_ref + ')' : null}
                        </Link>
                    </h4>
                    : null
            }
        </div>;
    }
}

export default locationHOC(OrderDetailsHeader);