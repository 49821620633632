import React from 'react';
import PropTypes from 'prop-types';
import Select from '../general/Select';
import withDataHOC from '../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../constants/globalData';
import {createFetchDealAll} from '../../backend/apiCalls';
import { formatPrice } from '../../lib/formatting';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import { Button } from 'antd';
import InputText from '../general/InputText';

/**
 *
 * @dusan
 */

class DealCodeSelect extends React.PureComponent {
    static propTypes = {
        customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        value: PropTypes.string,
        focus: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        allowClear: PropTypes.bool,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: "d-flex align-items-center",
    };

    constructor(props) {
        super(props);
        this.state = {
            deals: [],
            showSelect: false,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {showSelect} = this.state;
        const {customerId} = this.props;
        const {showSelect: prevShowSelect} = prevState;
        const {customerId: prevCustomerId} = prevProps;
        if(showSelect && (customerId != prevCustomerId || showSelect != prevShowSelect))
            this.fetchDeals();
    }

    fetchDeals = () => {
        const {customerId, [GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        if(customerId == null)
        {
            this.setState({deals: []});
            return;
        }

        fetchHandler(
            createFetchDealAll(),
            {id_customer: customerId},
            (result) => {
                this.setState({deals: result})
            }
        )
    };

    onFinishAddingDeal = (result) => {
        const {onChange} = this.props;
        this.fetchDeals();
        if(onChange != null)
            onChange(result);
    }

    render() {
        const {customerId, className, [GLOBAL_DATA.FETCH_HANDLER]: fetchHandler, [SESSION_ATTRIBUTES.SETTINGS]: settings,  ...props} = this.props;   
        const {deals, showSelect} = this.state;
        if(showSelect)
        {
            return <div className={className}>
                <Select
                    className="flex-grow-1"
                    {...props}
                    options={deals.map(d => {
                        return {
                            value: d.code,
                            label: d.code + ' (' + formatPrice(d.amount, settings.default_currency) + ')'
                        }
                    })}
                />
                <Button
                    className="ml-1"
                    icon="check"
                    onClick={() => this.setState({showSelect: false})}
                />
            </div>;
        }
        else
        {
            return <div className={className}>
                <InputText
                    className="flex-grow-1"
                    {...props}
                />
                { customerId != null ? 
                    <Button
                        className="ml-1"
                        icon="filter"
                        onClick={() => this.setState({showSelect: true})}
                    />
                    : null
                }
            </div>;
        }
    }
}

export default withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(
    withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(DealCodeSelect)
);