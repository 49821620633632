import React from 'react';
import PropTypes from 'prop-types';
import {Decimal, TransportType, UserAddress} from '../../../constants/propTypesDefinitions';
import { Button, Form } from 'antd';
import FormModal from '../../fetch/FormModal';
import {Trans, t} from '@lingui/macro';
import Address from '../../general/Address';
import generalFailedPC from '../../fetch/generalFailedPC';
import DeliveryAddress from './DeliveryAddress';
import { formItemLayout, tailFormItemLayout } from '../../../constants/form';
const FormItem = Form.Item;

/**
 * @dusan
 */

class DeliveryAddressModal extends React.PureComponent {
    static propTypes = {
        currentOption: UserAddress,
        onChange: PropTypes.func.isRequired,
        onPickupPointChange: PropTypes.func,
        allowClear: PropTypes.bool,
        placeholder: PropTypes.node,
        isSelling: PropTypes.bool,
        isProxy: PropTypes.bool,
        customerId: Decimal,
        userId: Decimal,
        transportType: TransportType,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: "full-size-width",
    };

    render() {
        const {className, currentOption, ...props} = this.props;

        return <div className={className}>
            { currentOption != null ?
                <div className="table-subdata">
                    <Trans>Dodacia adresa:</Trans>
                    <Address address={currentOption}/>
                </div>
                : null
            }
            <FormModal
                className={className}
                openNode={
                    <Button size="small" className="full-size-width">
                        {currentOption != null ? <Trans>zmeniť adr.</Trans> : <Trans>vybrať adr.</Trans>}
                    </Button>
                }
                values={{currentOption, ...props}}
                title={<Trans>Adresa doručenia</Trans>}
                Form={DeliveryAddressFormWrapped}
                Response={null}
                onFinishSuccessful={() => {}}
                Failed={generalFailedPC(t`Nepodarilo upraviť dodaciu adresu.`)}
            />
        </div>;
    }
}

export default  DeliveryAddressModal;

class DeliveryAddressForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        values: PropTypes.shape({
            currentOption: UserAddress,
            customerId: Decimal,
            userId: Decimal,
            transportType: TransportType,
            isSelling: PropTypes.bool,
            isProxy: PropTypes.bool,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }


    handleSubmit = (e) => {
        const {values, onClose} = this.props;
        const {onChange} = values;
        
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err && onChange != null) {
                this.setState({loading: true});
                onChange(formValues.id_address);
                this.setState({loading: false});
                onClose();
            }
        });
    };

    render() {
        const {onClose, values} = this.props;
        const {currentOption, onChange, ...rest} = values;
        const {getFieldDecorator} = this.props.form;
        const {loading} = this.state;

        return <Form onSubmit={this.handleSubmit}>
            <FormItem 
                {...formItemLayout}
                label={<Trans>Dodacia adresa</Trans>}
            >
                {getFieldDecorator('id_address', {initialValue: currentOption != null ? currentOption.id : null})(
                    <DeliveryAddress
                        currentOption={currentOption}
                        {...rest}
                    />
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" onClick={this.handleSubmit} loading={loading}>
                    <Trans>Uložiť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const DeliveryAddressFormWrapped = Form.create()(DeliveryAddressForm);