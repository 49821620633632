import React from 'react';
import PropTypes from 'prop-types';
import {Decimal, TransportType, UserAddress} from '../../../constants/propTypesDefinitions';
import withDataHOC from '../../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../../constants/globalData';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import Address from '../../general/Address';
import { Button } from 'antd';
import AddressSelect from '../../project/AddressSelect';
import { TRANSPORT_TYPE } from '../../../constants/transportTypes';
import { RIGHTS } from '../../../constants/Rights';

/**
 * @dusan
 */

class DeliveryAddress extends React.PureComponent {
    static propTypes = {
        value: PropTypes.number,
        currentOption: UserAddress,
        onChange: PropTypes.func.isRequired,
        onPickupPointChange: PropTypes.func,
        allowClear: PropTypes.bool,
        placeholder: PropTypes.node,
        isSelling: PropTypes.bool,
        isProxy: PropTypes.bool,
        customerAddresses: PropTypes.arrayOf(UserAddress.isRequired).isRequired,
        transportType: TransportType,
    };

    render() {
        const {
            currentOption, value, onChange, onPickupPointChange, allowClear, 
            transportType, isSelling, isProxy, customerAddresses, placeholder,
            [SESSION_ATTRIBUTES.SETTINGS]: settings,
            [SESSION_ATTRIBUTES.RIGHTS]: rights,
            [GLOBAL_DATA.ACCOUNT_ADDRESSES]: accountAddresses,
            [GLOBAL_DATA.COMPANY_ADDRESSES]: companyAddresses,
        } = this.props;

        const transportTypeChecked = transportType != null ? transportType : {};
        
        if(transportTypeChecked.use_pickup_points)
        {
            let onEdit = null;
            if(transportTypeChecked.courier_class == 'Packeta' && onPickupPointChange != null && window.Packeta !== undefined)
                onEdit = () => { window.Packeta.Widget.pick(settings.packeta_api_key, onPickupPointChange) };

            return <div className="d-flex align-items-center">
                <script src={settings.packeta_widget_src}/>
                <Address 
                    address={currentOption} 
                    nullOption={placeholder}
                />
                { onEdit != null ? 
                    <Button 
                        size="small" 
                        icon="edit"
                        className="ml-2"
                        onClick={onEdit}
                    /> : null
                }
            </div>;
        }

        if(isProxy || rights < RIGHTS.MARKETING)
        {
            return <Address 
                address={currentOption} 
                className="pr-2"
                nullOption={placeholder}
            />;
        }

        let addresses = [];

        if(transportTypeChecked.id == TRANSPORT_TYPE.AT_STORE)
        {
            // select one of the company's addresses
            addresses = companyAddresses;
        }
        else if(isSelling)
        {
            // select one of customer's addresses
            addresses = customerAddresses;
        }
        else
        {
            // select address by account
            addresses = accountAddresses;
        }

        return <AddressSelect
            addresses={addresses}
            value={value}
            currentOption={currentOption}
            onChange={onChange}
            allowClear={allowClear}
            placeholder={placeholder}
        />;
    }
}

export default withSessionHOC([SESSION_ATTRIBUTES.SETTINGS, SESSION_ATTRIBUTES.RIGHTS])(
    withDataHOC([GLOBAL_DATA.ACCOUNT_ADDRESSES, GLOBAL_DATA.COMPANY_ADDRESSES])(DeliveryAddress)
);