import React from 'react';
import PropTypes from 'prop-types';
import locationHOC from '../../locationProvider/locationHOC';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {navigateToParametrized} from '../../../lib/url';
import {QUERY_PARAMS, ROUTES} from '../../../constants/navigation';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchOrderLoadToCart} from '../../../backend/apiCalls';
import {OrderDetail} from '../../../constants/propTypesDefinitions';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class CopyOrderToCart extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        orderDetails: OrderDetail.isRequired,
    };

    navigateToCart = () => {
        const {location, orderDetails = {}} = this.props;
        const orderCustomer = orderDetails.customer != null ? orderDetails.customer : {};
        const customerId = orderCustomer.id;
        navigateToParametrized(location, ROUTES.CART, {
            [QUERY_PARAMS.ID_CUSTOMER]: customerId,
            [QUERY_PARAMS.CART_STEP]: 0,
        });
    };

    render() {
        const {orderDetails} = this.props;
        const orderId = orderDetails.id;
        return <FormModal
            openNode={
                <Button>
                    <Trans>Pridať do košíka</Trans>
                </Button>
            }
            values={{}}
            onFinishSuccessful={this.navigateToCart}
            title={<Trans>Kopírovanie obsahu objednávky do košíka</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete pridať položky z objednávky do košíka?</Trans>,
                createFetchOrderLoadToCart(orderId),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa skopírovať položky obejdnávky do košíka.`)}
        />;
    }
}

export default locationHOC(CopyOrderToCart);