import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';
import {TransferTypes, Trade} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class TransferTypesSelect extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.TRANSFER_TYPES]: TransferTypes.isRequired,
        onChange: PropTypes.func,
        trade: Trade,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string.isRequired)]),
        focus: PropTypes.bool,
        allowClear: PropTypes.bool,
    };

    render() {
        const { [GLOBAL_DATA.TRANSFER_TYPES]: transferTypes, trade, ...props } = this.props;
        const transferTypesFiltered = (
            trade != null ? transferTypes.filter(type => type.trade == trade) : transferTypes
        );
        return <Select
            {...props}
            options={transferTypesFiltered.map(transferType => {
                return {
                    label: transferType.id + ' - ' + transferType.name,
                    value: transferType.id,
                }
            })}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.TRANSFER_TYPES])(TransferTypesSelect);