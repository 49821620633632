import React from 'react';
import PropTypes from 'prop-types';
import Price from '../general/Price';
import {Trans, t} from '@lingui/macro';
import { Decimal } from '../../constants/propTypesDefinitions';

/**
 * @fero
 */

class PriceWhole extends React.PureComponent {
    static propTypes = {
        linesCount: PropTypes.number,
        prices: PropTypes.shape({
            sum_netto: Decimal,
            sum_brutto: Decimal,
            total_mass: Decimal,
            voucher_value: Decimal,
            voucher_netto: Decimal,
            price_to_pay: Decimal
        }).isRequired,
        currency: PropTypes.string,
    };

    render() {
        const {linesCount, prices, currency} = this.props;
        const totalMass = prices != null ? (prices.total_mass != null ? prices.total_mass : null) : null;
        const priceNetto = prices != null ? (prices.sum_netto != null ? prices.sum_netto : null) : null;
        const priceBrutto = prices != null ? (prices.sum_brutto != null ? prices.sum_brutto : null) : null;
        const voucherBrutto = prices != null ? (prices.voucher_value != null ? prices.voucher_value : null) : null;
        const priceToPay = prices != null ? (prices.price_to_pay != null ? prices.price_to_pay : null) : null;
        return <div className="d-flex flex-column justify-content-end align-items-end">
            { linesCount != null ? <div className="d-flex justify-content-end">
                <h4><Trans>Počet položiek:</Trans></h4>
                <div className="pl-2 price-summary-value"><h4>{linesCount}</h4></div>
                </div> : null
            }
            { totalMass != null ? <div className="d-flex justify-content-end">
                <h4><Trans>Hmotnosť:</Trans></h4>
                <div className="pl-2 price-summary-value"><h4>{'>' + totalMass + ' kg'}</h4></div>
                </div> : null
            }
            { voucherBrutto != null ? 
                <div className="d-flex justify-content-end">
                    <h4><Trans>Darčekový poukaz / zľava:</Trans></h4>
                    <div className="pl-2 price-summary-value">
                        <h4><Price price={voucherBrutto} currency={currency}/></h4>
                    </div>
                </div> : null
            }
            <div className="d-flex justify-content-end">
                <h4><Trans>Cena spolu bez DPH:</Trans></h4>
                <div className="pl-2 price-summary-value">
                    <h4><Price price={priceNetto} currency={currency}/></h4>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <h4><Trans>Cena spolu s DPH:</Trans></h4>
                <div className="pl-2 price-summary-value">
                    <h4><Price price={priceBrutto} currency={currency}/></h4>
                </div>
            </div>
            { priceToPay != null ? 
                <div className="d-flex justify-content-end">
                    <h4><Trans>Na úhradu:</Trans></h4>
                    <div className="pl-2 price-summary-value">
                        <h4><Price price={priceToPay} currency={currency}/></h4>
                    </div>
                </div> : null
            }
        </div>;
    }

}

export default PriceWhole;