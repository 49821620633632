import React from 'react';
import PropTypes from 'prop-types';
import {formatProductName} from '../../../../lib/formatting';
import {ComponentDetails, Customer, OrderDetail} from '../../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import DisplayModal from '../../../general/DisplayModal';
import ComponentDetailsTable from './ComponentDetailsTable';
import { Button } from 'antd';

/**
 * @fero
 */

class ComponentDetailsModal extends React.PureComponent {
    static propTypes = {
        reload: PropTypes.func.isRequired,
        componentDetails: ComponentDetails,
        productDesignation: PropTypes.string.isRequired,
        productManufacturer: PropTypes.string.isRequired,
        quantityUnits: PropTypes.string.isRequired,
        finalOrder: OrderDetail,
        missingQuantity: PropTypes.number,
    };


    render() {
        const {componentDetails, reload,  productDesignation, productManufacturer, quantityUnits, finalOrder, missingQuantity} = this.props;
        if(componentDetails == null)
            return null;
        
        const quantityOnStock = componentDetails.quantity_on_stock;
        const quantityAvailable = componentDetails.quantity_available;
        const quantityTotal = componentDetails.quantity_total;

        return <DisplayModal
            modalClassName="large-modal"
            title={
                <span>
                    <Trans>Komponenty produktu</Trans>
                    {' '}
                    {formatProductName(productDesignation, productManufacturer)}
                </span>
            }
            openNode={
                <Button size="small" className="full-size-width">
                    <Trans>Komponenty</Trans>
                </Button>
            }
        >
            <ComponentDetailsTable
                reload={reload}
                components={componentDetails.items}
                quantityAvailable={quantityAvailable}
                quantityOnStock={quantityOnStock}
                quantityTotal={quantityTotal}
                quantityUnits={quantityUnits}
                showOrderButton={true}
                finalOrder={finalOrder}
                missingQuantity={missingQuantity}
            />
        </DisplayModal>;
    }

}

export default ComponentDetailsModal;
