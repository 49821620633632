import React from 'react';
import PropTypes from 'prop-types';
import ButtonFetch from '../../fetch/ButtonFetch';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchOrderAddItem} from '../../../backend/apiCalls';
import {InputNumber} from 'antd';
import {Trans, t} from '@lingui/macro';
import AddProductToOrderResponse from './AddProductToOrderResponse';
import { Product } from '../../../constants/propTypesDefinitions';

/**
 * @fero
 */


class AddProductToOrder extends React.PureComponent {
    static propTypes = {
        orderId: PropTypes.number.isRequired,
        numberOfItems: PropTypes.number,
        onChangeNumberOfItems: PropTypes.func.isRequired,
        product: Product.isRequired,
    };

    onInputEnter = (event) => {
        if (
            this.addButton != null &&
            this.addButton.handleSubmit != null &&
            event != null &&
            event.keyCode == 13
        ) {
            this.addButton.handleSubmit();
        }
    };

    render() {
        const {onChangeNumberOfItems, numberOfItems, product, orderId} = this.props;
        return <div className="d-flex justify-content-center">
            <InputNumber
                size={'small'}
                className="text-right"
                onChange={onChangeNumberOfItems}
                value={numberOfItems}
                min={Number(product.quantity_min)}
                step={Number(product.quantity_div)}
                onKeyUp={this.onInputEnter}
            />
            <ButtonFetch
                ref={node => this.addButton = node}
                size={'small'}
                type="primary"
                icon="plus"
                disabled={numberOfItems <= 0}
                values={{
                    products: [{
                        designation: product.designation, 
                        numberOfItems: numberOfItems, 
                        quantityUnits: product.quantity_units
                    }]
                }}
                Response={AddProductToOrderResponse}
                Failed={generalFailedPC(t`Pridanie do objednávky zlyhalo`)}
                fetchFunction={createFetchOrderAddItem({
                    id_order: orderId,
                    id_product: product.id,
                    quantity: numberOfItems,
                })}
            />
        </div>;
    }

}

export default AddProductToOrder;