import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';

/**
 * @fero
 */

class CurrencySelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string,
        className: PropTypes.string,
        focus: PropTypes.bool,
    };

    static defaultProps = {
        className: 'currency-select',
    };

    render() {
        const { [GLOBAL_DATA.CURRENCIES]: currencies, value, focus, className, ...props } = this.props;
        return <Select
            className={className}
            {...props}
            focus={focus}
            options={currencies.map(tmp => {
                return {
                    label: tmp.id,
                    value: tmp.id,
                }
            })}
            value={value}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.CURRENCIES])(CurrencySelect);