import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import FormModal from '../../fetch/FormModal';
import {GLOBAL_DATA} from '../../../constants/globalData';
import generalFailedPC from '../../fetch/generalFailedPC';
import {OrderDetail} from '../../../constants/propTypesDefinitions';
import {Button, Form} from 'antd';
import {Trans, t} from '@lingui/macro';
import { createFetchOrderFitPrices } from '../../../backend/apiCalls';
import { formItemLayout, tailFormItemLayout, mandatoryRule, max100Rule, min100Rule } from '../../../constants/form';
import InputUnitPrice from '../../project/InputUnitPrice';
import { numberOrNull } from '../../../lib/number';
import { applyDiscount, percentageFromPriceDifference } from '../../../lib/computation';
import InputQuantity from '../../project/InputQuantity';
import InputPrice from '../../project/InputPrice';
const FormItem = Form.Item;

/**
 * @dusan
 */


class FitOrderPrices extends React.PureComponent {
    static propTypes = {
        orderDetails: OrderDetail.isRequired,
    };

    reloadOrder = () => {
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        reload([GLOBAL_DATA.ORDER_DETAILS]);
    };

    render() {
        const {orderDetails, ...props} = this.props;
        return <FormModal
            openNode={
                <Button {...props}>
                    <Trans>Zľava</Trans>
                </Button>
            }
            values={{orderDetails}}
            onFinishSuccessful={this.reloadOrder}
            title={<Trans>Prirážka / zľava na celú objednávku</Trans>}
            Form={FitOrderPricesFormWrapped}
            Failed={generalFailedPC(t`Nepodarilo sa uplatniť prirážku / zľavu.`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(FitOrderPrices);

class FitOrderPricesForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            orderDetails: OrderDetail.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    onPriceChange = (newVal) => {
        const {values} = this.props;
        const {orderDetails = {}} = values;
        const {setFieldsValue} = this.props.form;
        const oldPrice = numberOrNull(orderDetails.sum_netto);
        const newPrice = numberOrNull(newVal);
        const disc = oldPrice != null && newPrice != null && oldPrice != 0.0 ?
            -percentageFromPriceDifference(newPrice, oldPrice) : null;

        setFieldsValue({discount: disc});
    }

    onDiscountChange = (newVal) => {
        const {values} = this.props;
        const {orderDetails = {}} = values;
        const {setFieldsValue} = this.props.form;
        const oldPrice = numberOrNull(orderDetails.sum_netto);
        const discount = numberOrNull(newVal);
        const discountChecked = discount != null ? discount : 0.0;
        const newPrice = oldPrice != null && oldPrice != 0.0 ?
            applyDiscount(oldPrice, -discountChecked) : null;

        setFieldsValue({new_price: newPrice});
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        const {orderDetails = {}} = values;

        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                createFetchOrderFitPrices()({
                    id: orderDetails.id,
                    new_price: formValues.new_price
                }).then((response) => {
                    this.setState({loading: false});
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {onClose, values} = this.props;
        const {orderDetails = {}} = values;
        const {loading} = this.state;
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Pôvodná suma bez DPH</Trans>}>
                {getFieldDecorator('old_price', {
                    initialValue: orderDetails.sum_netto
                })(
                    <InputPrice
                        className="text-left"
                        disabled={true}
                        currency={orderDetails.id_currency}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Nová suma bez DPH</Trans>}>
                {getFieldDecorator('new_price', {
                    rules: [mandatoryRule],
                    onChange: this.onPriceChange
                })(
                    <InputPrice
                        className="text-left"
                        currency={orderDetails.id_currency}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Prirážka(+) / Zľava(-)</Trans>}
            >
                {getFieldDecorator('discount', {
                    rules: [max100Rule, min100Rule],
                    onChange: this.onDiscountChange
                })(
                    <InputQuantity
                        unit={'%'}
                        changeImmediately={true}
                    />
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" onClick={this.handleSubmit} loading={loading}>
                    <Trans>Uložiť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const FitOrderPricesFormWrapped = Form.create()(FitOrderPricesForm);