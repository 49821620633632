import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import Tooltip from '../../general/Tooltip';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {OrderDetail} from '../../../constants/propTypesDefinitions';
import {createFetchOrderCancel} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import withDataHOC from '../../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../../constants/globalData';
import { isOrderFinished } from '../../../lib/project';
/**
 * @fero
 */

class CancelOrder extends React.PureComponent {
    static propTypes = {
        orderDetails: OrderDetail.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const {orderDetails, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        const disabled = isOrderFinished(orderDetails);
        return <FormModal
            disabled={disabled}
            openNode={
                <Tooltip title={<Trans>Zrušiť všetky položky v objednávke</Trans>}>
                    <Button type="danger" disabled={disabled}>
                        <Trans>Zrušiť objednávku</Trans>
                    </Button>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={() => {
                reload([GLOBAL_DATA.ORDER_DETAILS])
            }}
            title={
                <span>
                    <Trans>Zrušiť všetky položky v objednávke</Trans>
                    {' '}
                    {orderDetails.dealer_reference}
                </span>
            }
            Form={generalNoParameterFormPC(
                <Trans>Chcete zrušiť všetky položky v objednávke?</Trans>,
                createFetchOrderCancel(orderDetails.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa zrušiť objednávku.`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(CancelOrder);