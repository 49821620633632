import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import FormModal from '../../fetch/FormModal';
import {GLOBAL_DATA} from '../../../constants/globalData';
import generalFailedPC from '../../fetch/generalFailedPC';
import {OrderDetail} from '../../../constants/propTypesDefinitions';
import {AutoComplete, Button, Divider, Form, Radio} from 'antd';
import {Trans, t} from '@lingui/macro';
import { createFetchCustomerAddresses, createFetchCustomerAll, createFetchOrderEdit } from '../../../backend/apiCalls';
import { formItemLayout, tailFormItemLayout, mandatoryRule } from '../../../constants/form';
import InputText from '../../general/InputText';
import CountriesSelect from '../../project/CountriesSelect';
import LanguageSelect from '../../project/LanguageSelect';
import { getInputOnChangeEventValue, isTradeSell } from '../../../lib/project';
import AddressSelect from '../../project/AddressSelect';
const FormItem = Form.Item;

/**
 * @dusan
 */


class EditFinalOrder extends React.PureComponent {
    static propTypes = {
        orderDetails: OrderDetail.isRequired,
    };

    reloadOrder = () => {
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        reload([GLOBAL_DATA.ORDER_DETAILS]);
    };

    render() {
        const {orderDetails, ...props} = this.props;
        return <FormModal
            openNode={
                <Button icon="edit" size="small" {...props}/>
            }
            values={{orderDetails}}
            onFinishSuccessful={this.reloadOrder}
            title={<Trans>Parametre koncovej objednávky</Trans>}
            Form={EditFinalOrderFormWrapped}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť parametre koncovej objednávky.`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(EditFinalOrder);

class EditFinalOrderForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            orderDetails: OrderDetail.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingAddresses: false,
            proxyCustomers: [],
            proxyAddresses: [],
            proxyAddressId: null,
            selectProxyAddress: false,
        };
    }

    fetchProxyCustomers = (phrase) => {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;

        if(phrase == null || phrase == "" || phrase.length < 3)
        {
            this.setState({proxyCustomers: []});
            return false;
        }

        fetchHandler(
            createFetchCustomerAll(),
            {phrase: phrase},
            (result) => {
                this.setState({
                    proxyCustomers: result
                });
            }
        );

        return true;
    };

    onProxyCustomerSelect = (value) => {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        const {setFieldsValue} = this.props.form;
        const {proxyCustomers} = this.state;
        
        const proxCustomer = proxyCustomers != null && Array.isArray(proxyCustomers) ?
            proxyCustomers.find(c => c.name == value)
            : 
            null;

        const proxyCustomerId = proxCustomer != null ? proxCustomer.id : null;
        if(proxyCustomerId != null)
        {
            // get customer addresses
            this.setState({loadingAddresses: true});

            fetchHandler(
                createFetchCustomerAddresses(),
                {id_customer: proxyCustomerId},
                (result) => {
                    // select first address
                    const addressChecked = result.length > 0 ? result[0] : {};
                    setFieldsValue({
                        'final_order[user][e_mail]': addressChecked.e_mail,
                        'final_order[user][fullname]': addressChecked.person,
                        'final_order[user][e_mail]': addressChecked.phone,
                        'final_order[delivery_address][street]': addressChecked.street,
                        'final_order[delivery_address][city]': addressChecked.city,
                        'final_order[delivery_address][zip_code]': addressChecked.zip_code,
                        'final_order[delivery_address][id_country]': addressChecked.id_country,
                    });

                    this.setState({
                        loadingAddresses: false,
                        proxyAddresses: result,
                        selectProxyAddress: true,
                        proxyAddressId: addressChecked.id
                    });
                }
            );
        }
        else
        {
            this.setState({
                proxyAddresses: [], 
                proxyAddressId: null,
                selectProxyAddress: false
            });
        }
    }

    onProxyAddressSelect = (newAddressId) => {
        const {proxyAddresses} = this.state;
        const {setFieldsValue} = this.props.form;
        const address = newAddressId != null && proxyAddresses != null && Array.isArray(proxyAddresses) ?
             proxyAddresses.find(a => a.id == newAddressId) : null;

        const addressChecked = address != null ? address : {};
        this.setState({proxyAddressId: newAddressId});

        setFieldsValue({
            'final_order[user][e_mail]': addressChecked.e_mail,
            'final_order[user][fullname]': addressChecked.person,
            'final_order[user][e_mail]': addressChecked.phone,
            'final_order[delivery_address][street]': addressChecked.street,
            'final_order[delivery_address][city]': addressChecked.city,
            'final_order[delivery_address][zip_code]': addressChecked.zip_code,
            'final_order[delivery_address][id_country]': addressChecked.id_country,
        });
    }

    handleSubmit = (e) => {
        const {onSubmitFinish} = this.props;
        
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                createFetchOrderEdit()(formValues).then((response) => {
                    this.setState({loading: false});
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {onClose, values} = this.props;
        const {orderDetails = {}} = values;
        const {loading, proxyCustomers, proxyAddresses, selectProxyAddress, proxyAddressId, loadingAddresses} = this.state;
        const {getFieldDecorator} = this.props.form;
        const finalOrder = orderDetails.final_order != null ? orderDetails.final_order : {};
        const finalCustomer = finalOrder.customer != null ? finalOrder.customer : {};
        const finalUser = finalOrder.user != null ? finalOrder.user : {};
        const finalAddress = finalOrder.delivery_address != null ? finalOrder.delivery_address : {};
        const proxyCustomerNames = proxyCustomers != null && Array.isArray(proxyCustomers) ? proxyCustomers.map(c => c.name) : [];

        return <Form onSubmit={this.handleSubmit}>
            {getFieldDecorator('id', {initialValue: orderDetails.id})}
            {getFieldDecorator('final_order[ext_id]', {initialValue: finalOrder.ext_id})}
            <FormItem
                {...formItemLayout}
                label={isTradeSell(orderDetails.trade) ? <Trans>Koncový zákazník</Trans> : <Trans>Dodávateľ</Trans>}>
                {getFieldDecorator('final_order[customer][name]', {
                    rules: [mandatoryRule],
                    initialValue: finalCustomer.name
                })(
                    <AutoComplete
                        className="full-size-width"
                        dataSource={proxyCustomerNames}
                        onSearch={this.fetchProxyCustomers}
                        onSelect={this.onProxyCustomerSelect}
                        autoFocus={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Číslo koncovej objednávky</Trans>}>
                {getFieldDecorator('final_order[order_reference]', {
                    initialValue: finalOrder.order_reference
                })(
                    <InputText/>
                )}
            </FormItem>
            <Divider orientation="left"><Trans>Kontaktná osoba</Trans></Divider>
            <FormItem
                {...formItemLayout}
                label={<Trans>Meno a priezivsko</Trans>}>
                {getFieldDecorator('final_order[user][fullname]', {
                    initialValue: finalUser.fullname
                })(
                    <InputText/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Telefón</Trans>}>
                {getFieldDecorator('final_order[user][phone]', {
                    initialValue: finalUser.phone
                })(
                    <InputText/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>E-mail</Trans>}>
                {getFieldDecorator('final_order[user][e_mail]', {
                    initialValue: finalUser.e_mail
                })(
                    <InputText/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Jazyk</Trans>}>
                {getFieldDecorator('final_order[user][id_language]', {
                    initialValue: finalUser.id_language
                })(
                    <LanguageSelect/>
                )}
            </FormItem>
            <Divider orientation="left"><Trans>Adresa doručenia</Trans></Divider>
            { proxyAddresses.length > 0 ?
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Akcia</Trans>}>
                    {getFieldDecorator('address_action', {
                        initialValue: selectProxyAddress,
                        onChange: (e) => {
                            this.setState({selectProxyAddress: getInputOnChangeEventValue(e)})
                        }
                    })(
                        <Radio.Group>
                            <Radio.Button value={false}><Trans>Zadať novú adresu</Trans></Radio.Button>
                            <Radio.Button value={true}><Trans>Vybrať zo zoznamu</Trans></Radio.Button>
                        </Radio.Group>
                    )}
                </FormItem>
                : null
            }
            { selectProxyAddress ? 
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Dodacia adresa</Trans>}>
                    {getFieldDecorator('id_final_address', {
                        initialValue: proxyAddressId,
                        rules: [mandatoryRule],
                        onChange: this.onProxyAddressSelect
                    })(
                        <AddressSelect
                            addresses={proxyAddresses}
                            disabled={loadingAddresses}
                        />
                    )}
                </FormItem>
                : null
            }
            <FormItem
                {...formItemLayout}
                style={selectProxyAddress ? {display: 'none'} : {}}
                label={<Trans>Ulica a číslo</Trans>}>
                {getFieldDecorator('final_order[delivery_address][street]', {
                    rules: [mandatoryRule],
                    initialValue: finalAddress.street
                })(
                    <InputText/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                style={selectProxyAddress ? {display: 'none'} : {}}
                label={<Trans>Mesto</Trans>}>
                {getFieldDecorator('final_order[delivery_address][city]', {
                    rules: [mandatoryRule],
                    initialValue: finalAddress.city
                })(
                    <InputText/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                style={selectProxyAddress ? {display: 'none'} : {}}
                label={<Trans>PSČ</Trans>}>
                {getFieldDecorator('final_order[delivery_address][zip_code]', {
                    rules: [mandatoryRule],
                    initialValue: finalAddress.zip_code
                })(
                    <InputText/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                style={selectProxyAddress ? {display: 'none'} : {}}
                label={<Trans>Štát</Trans>}>
                {getFieldDecorator('final_order[delivery_address][id_country]', {
                    rules: [mandatoryRule],
                    initialValue: finalAddress.id_country
                })(
                    <CountriesSelect/>
                )}
            </FormItem>        
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" onClick={this.handleSubmit} loading={loading}>
                    <Trans>Uložiť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const EditFinalOrderFormWrapped = withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(Form.create()(EditFinalOrderForm));