import React from 'react';
import locationHOC from '../../locationProvider/locationHOC';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {PAYMENT_METHOD} from '../../../constants/paymentMethods';
import {getQueryParam, navigateToParametrized} from '../../../lib/url';
import {Button, Modal} from 'antd';
import {OrderDetail} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

const Thanks = () => {
    return <h3>
        <Trans>Ďakujeme za Vašu objednávku.</Trans>
    </h3>
};

const Success = () => {
    return <div className="text-center">
        <Thanks/>
        <br/>
        <Trans>Bol Vám odoslaný email s potvrdením.</Trans>
    </div>;
};

const GPSuccess = () => {
    return <div className="text-center">
        <Thanks/>
        <br/>
        <h3>
            <Trans>Platba prebehla úspešne.</Trans>
        </h3>
        <br/>
        <Trans>Bol Vám odoslaný email s potvrdením.</Trans>
    </div>;
};

const GPError = ({paymentResult}) => {
    return <div className="text-center">
        <Thanks/>
        <br/>
        <h3>
            <Trans>Pri platbe kartou nastala chyba.</Trans>
        </h3>
        <br/>
        <Trans>Správa od platobnej brány:</Trans>
        {' '}
        {paymentResult}
        <br/>
        <br/>
        <Trans>Platbu možete skúsiť uskutočniť znovu v detaile objednávky, ktorý bude zobrazený po zatvorení tohto okna(sekcia Zaplatené).</Trans>
        <br/>
        <br/>
        <Trans>V prípade, ak potrebujete s vyriešením chyby pomôcť, prosím kontaktujete našich obchodníkov na </Trans>
        {' '}
        <a className="text-dark" href="mailto:predajna@kbm.sk">predajna@kbm.sk</a>
        <Trans>, ktorí Vám radi pomôžu.</Trans>
    </div>;
};

const Response = ({paymentMethodId, paymentResult}) => {
    if(paymentMethodId == PAYMENT_METHOD.GP_ONLINE_GATEWAY) {
        return paymentResult == 'OK' ? <GPSuccess /> : <GPError paymentResult={paymentResult} />
    } else {
        return <Success />
    }
};

class OrderReceivedModal extends React.PureComponent {
    static propTypes = {
        orderDetails: OrderDetail.isRequired,
    };

    hidePaymentMessage = () => {
        const {location} = this.props;
        navigateToParametrized(location, null, {[QUERY_PARAMS.ORDER_DETAIL_SHOW_PAYMENT_MESSAGE]: undefined})
    };

    render() {
        const {orderDetails = {}, location} = this.props;
        const paymentMethodId = orderDetails.payment_method ? orderDetails.payment_method.id : null;
        const paymentResult = orderDetails.payment_result;
        const showPaymentMessage = getQueryParam(location, QUERY_PARAMS.ORDER_DETAIL_SHOW_PAYMENT_MESSAGE) == 1;
        return <Modal
            visible={showPaymentMessage}
            title={<Trans>Objednávka prijatá</Trans>}
            onCancel={this.hidePaymentMessage}
            destroyOnClose={true}
            keyboard={true}
            closable={true}
            maskClosable={false}
            footer={
                <Button
                    onClick={this.hidePaymentMessage}
                >
                    <Trans>Zavrieť</Trans>
                </Button>
            }
        >
            <Response paymentMethodId={paymentMethodId} paymentResult={paymentResult} />
        </Modal>;
    }
}

export default locationHOC(OrderReceivedModal);