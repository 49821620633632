import React from 'react';
import PropTypes from 'prop-types';
import {ProductStockOptions} from '../../constants/propTypesDefinitions';
import {Button, Form} from 'antd';
import {Trans, t} from '@lingui/macro';
import FormModal from '../fetch/FormModal';
import generalFailedPC from '../fetch/generalFailedPC';
import { formItemLayout, tailFormItemLayout } from '../../constants/form';
import { GLOBAL_DATA } from '../../constants/globalData';
import Select from '../general/Select';
import withDataHOC from '../dataProvider/withDataHOC';
import { formatQuantityAvailable } from '../../lib/formatting';
import { createFetchProductStockOptions } from '../../backend/apiCalls';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
const FormItem = Form.Item;

/**
 * @dusan
 */

class StockOptionsSelect extends React.PureComponent {
    static propTypes = {
        productId: PropTypes.number.isRequired,
        customerId: PropTypes.number,
        value: PropTypes.number, // stockId
        label: PropTypes.string,
        quantityUnits: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        className: PropTypes.string,
    };

    static defaultProps = {
        disabled: false,
        className: "full-size-width",
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            options: []
        };
    }

    fetchOptions = () => {
        const {customerId, productId, [GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;

        this.setState({loading: true});
        fetchHandler(
            createFetchProductStockOptions(),
            {
                id_product: productId,
                id_customer: customerId
            },
            (result) => {
                this.setState({
                    options: result,
                    loading: false,
                });
            }
        );
    };

    render() {
        const {disabled, className, value, label, quantityUnits, onChange, [SESSION_ATTRIBUTES.SETTINGS]: settings} = this.props;
        const {options} = this.state;

        if(!settings.show_available_quantity)
            return null;

        return <div className={className}>
            { label != null ?
                <div className="table-subdata">
                    <Trans>sklad</Trans>
                    {' '}
                    {label}
                </div>
                : null
            }
            { !disabled ? 
                <FormModal
                    className={className}
                    openNode={
                        <Button size="small" className="full-size-width">
                            {value != null ? <Trans>zmeniť skl.</Trans> : <Trans>vybrať skl.</Trans>}
                        </Button>
                    }
                    values={{stockId: value, options, quantityUnits, onChange}}
                    onOpen={this.fetchOptions}
                    title={<Trans>Vydať zo skladu</Trans>}
                    Form={StockOptionsFormWrapped}
                    Response={null}
                    onFinishSuccessful={() => {}}
                    Failed={generalFailedPC(t`Nepodarilo upraviť sklad.`)}
                /> :
                null
            }
        </div>;
    }
}

export default  withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(
    withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(StockOptionsSelect)
);

class StockOptionsForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        values: PropTypes.shape({
            stockId: PropTypes.number,
            quantityUnits: PropTypes.string,
            options: ProductStockOptions.isRequired,
            onChange: PropTypes.func.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }


    handleSubmit = (e) => {
        const {values, onClose} = this.props;
        const {onChange} = values;
        
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err && onChange != null) {
                this.setState({loading: true});
                onChange(formValues.id_stock);
                this.setState({loading: false});
                onClose();
            }
        });
    };

    render() {
        const {onClose, values} = this.props;
        const {stockId, options, quantityUnits} = values;
        const {getFieldDecorator} = this.props.form;
        const {loading} = this.state;

        return <Form onSubmit={this.handleSubmit}>
            <FormItem 
                {...formItemLayout}
                label={<Trans>Sklad</Trans>}
            >
                {getFieldDecorator('id_stock', {initialValue: stockId})(
                    <Select 
                        allowClear={true}
                        placeholder="všetky sklady"
                        options={options.map((o) => {
                            return {
                                value: o.id_stock, 
                                label: (o.stock != null ? t`sklad ${o.stock}`  : t`hl. sklad`) +
                                    ': ' + formatQuantityAvailable(o.quantity_available, quantityUnits) +
                                    ' ' + (o.supply_duration == 0 ? t`ihneď` : t`do ${o.supply_duration} prac. dní`)
                            }
                        })}
                    />
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" onClick={this.handleSubmit} loading={loading}>
                    <Trans>Uložiť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const StockOptionsFormWrapped = Form.create()(StockOptionsForm);