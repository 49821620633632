import React from 'react';
import PropTypes from 'prop-types';
import PayRest from './PayRest';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import withDataHOC from '../../dataProvider/withDataHOC';
import EmployeesSelect from '../../project/EmployeesSelect';
import PaymentMethodSelect from '../../project/PaymentMethodSelect';
import TransportTypeSelect from '../../project/TransportTypeSelect';
import StocksSelect from '../../project/StocksSelect';
import {getInputOnChangeEventValue, isOrderFinished, isOrderRemote, isTradeSell} from '../../../lib/project';
import YesNo from '../../project/YesNo';
import InputNumber from '../../general/InputNumber';
import InputTextArea from '../../general/InputTextArea';
import Address from '../../general/Address';
import Date from '../../general/Date';
import DateTime from '../../general/DateTime';
import NamePrice from '../../general/NamePrice';
import EmailLink from '../../general/EmailLink';
import PhoneLink from '../../general/PhoneLink';
import InfoAttributeGroup from '../../general/InfoAttributeGroup';
import ChannelOptionsDisplay from '../../project/ChannelOptionsDisplay';
import {OrderDetail, UserAddress} from '../../../constants/propTypesDefinitions';
import {RIGHTS} from '../../../constants/Rights';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {merge} from '../../../lib/object';
import {formatPrice, formatString} from '../../../lib/formatting';
import {createFetchOrderEdit} from '../../../backend/apiCalls';
import {Button, Icon, notification, Radio} from 'antd';
import {Trans, t} from '@lingui/macro';
import CustomerLink from '../../general/CustomerLink';
import UserLink from '../../general/UserLink';
import InputText from '../../general/InputText';
import {isOrderSend} from '../../../lib/project';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import AddressSelectByCustomer from '../../project/AddressSelectByCustomer';
import UserSelectByCustomer from '../../project/UserSelectByCustomer';
import AddressSelect from '../../project/AddressSelect';
import TransferTypesSelect from '../../project/TransferTypesSelect';
import { PAYMENT_METHOD } from '../../../constants/paymentMethods';
import MakeProformaInvoice from './MakeProformaInvoice';
import DatePicker from '../../general/DatePicker';
import Tooltip from '../../general/Tooltip';
import { addQueryParamsToUrl } from '../../../lib/url';
import { STOCK_ROOT } from '../../../constants/apiEndpoints';
import { STOCK_ROUTES } from '../../../constants/navigation';
import CustomersSelect from '../../project/CustomersSelect';
import CurrencySelect from '../../project/CurrencySelect';
import DepartmentsSelect from '../../project/DepartmentsSelect';
import EditAddress from '../../account/address/EditAddress';
import EditFinalOrder from './EditFinalOrder';
import ChildPriceLevelSelect from '../../project/ChildPriceLevelSelect';
import InputPrice from '../../project/InputPrice';
import DealCodeSelect from '../../project/DealCodeSelect';
import DeliveryAddress from './DeliveryAddress';
import OrderCurrency from './OrderCurrency';

/**
 * @fero
 */

class OrderDetailsInfo extends React.PureComponent {
    static propTypes = {
        orderDetails: OrderDetail.isRequired,
        customerAddresses: PropTypes.arrayOf(UserAddress.isRequired).isRequired,
        [GLOBAL_DATA.ACCOUNT_ADDRESSES]: PropTypes.arrayOf(UserAddress.isRequired).isRequired,
        [GLOBAL_DATA.COMPANY_ADDRESSES]: PropTypes.arrayOf(UserAddress.isRequired).isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            editingDate: false,
            editingCustomer: false,
            editingDepartment: false,
            editingUser: false,
            editingPriceLevel: false,
        };
    }

    onDealerChange = (dealerId) => {
        this.editOrder({id_dealer: dealerId});
    };

    onCustomerChange = (customerId) => {
        this.editOrder({id_customer: customerId});
    };

    onDealerNoticesChange = (dealerNotices) => {
        this.editOrder({dealer_notices: dealerNotices});
    };

    onCustomerNoticesChange = (customerNotices) => {
        this.editOrder({customer_notices: customerNotices});
    };

    onTransportTypeChange = (transportTypeId) => {
        this.editOrder({id_transport_type: transportTypeId});
    };

    onTransportPriceChange = (transportPrice) => {
        this.editOrder({transport_price: transportPrice});
    };

    onPaymentMethodChange = (paymentMethodId) => {
        this.editOrder({id_payment_method: paymentMethodId});
    };

    onPaymentPriceChange = (paymentPrice) => {
        this.editOrder({payment_price: paymentPrice});
    };

    onIsDivisibleChange = (isDivisible) => {
        this.editOrder({is_divisible: isDivisible});
    };

    onStockChange = (stockIds) => {
        this.editOrder({id_stock: stockIds});
    };

    onDstStockChange = (stockId) => {
        this.editOrder({id_dst_stock: stockId});
    };

    onTransferTypeChange = (typeId) => {
        this.editOrder({id_transfer_type: typeId});
    };

    onCustomerReferenceChange = (customerRef) => {
        this.editOrder({customer_reference: customerRef});
    };

    onDealCodeChange = (dealCode) => {
        this.editOrder({deal_code: dealCode});
    };

    onUserChange = (userId) =>  {
        this.editOrder({id_user: userId});
    };

    onDepartmentChange = (departmentId) =>  {
        this.editOrder({id_department: departmentId});
    };

    onPriceLevelChange = (priceLevelId) =>  {
        this.editOrder({id_price_level: priceLevelId});
    };

    onSentAtChange = (newDate) =>  {
        this.editOrder({sent_at: newDate});
    };

    onInvoiceAddressChange = (addressId) => {
        this.editOrder({id_invoice_address: addressId});
    };

    onDeliveryAddressChange = (addressId) => {
        this.editOrder({id_delivery_address: addressId});
    };

    onCurrencyChange = (currencyId) => {
        this.editOrder({id_currency: currencyId});
    };

    onExchangeRateChange = (rate) => {
        this.editOrder({exchange_rate: rate});
    };

    onVoucherValueChange = (voucherVal) => {
        this.editOrder({voucher_value: voucherVal});
    };

    onPickupPointChange = (point) => {
        this.editOrder({pickup_point_ref: point != null ? point.id : null});
    };

    editOrder = (queryParams) => {
        const {orderDetails} = this.props;
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        fetchHandler(createFetchOrderEdit(),
            merge({id: orderDetails.id}, queryParams),
            null,
            this.reloadOrder,
            (error) => {
                notification['error']({ 
                    message: error.message,
                    duration: 10,
                });
            }
        );
    };

    reloadOrder = () => {
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        reload([GLOBAL_DATA.ORDER_DETAILS]);
    };

    render() {
        const {
            orderDetails = {}, customerAddresses,
            [GLOBAL_DATA.ACCOUNT_ADDRESSES]: accountAddresses,
            [SESSION_ATTRIBUTES.SETTINGS]: settings,
        } = this.props;
        const {editingDate, editingCustomer, editingDepartment, editingUser, editingPriceLevel} = this.state;
        const {payment_method: paymentMethod = {}, transport_type: transportType = {}, } = orderDetails;
        const customerChecked = orderDetails.customer != null ? orderDetails.customer : {};
        const userChecked = orderDetails.user != null ? orderDetails.user : {};
        const dealerChecked = orderDetails.dealer != null ? orderDetails.dealer : {};
        const departmentChecked = orderDetails.department != null ? orderDetails.department : {};
        const invoiceAddressChecked = orderDetails.invoice_address != null ? orderDetails.invoice_address : {};
        const deliveryAddressChecked = orderDetails.delivery_address != null ? orderDetails.delivery_address : {};
        const prepaymentNumber = Number.isNaN(orderDetails.prepayment) ? 0 : Number(orderDetails.prepayment);
        const wholePrice = Number.isNaN(orderDetails.sum_brutto) ? null : Number(orderDetails.sum_brutto);
        const isSent = isOrderSend(orderDetails);
        const shipUser = (deliveryAddressChecked.contact || userChecked.fullname || '');
        const shipEmail = (deliveryAddressChecked.e_mail || userChecked.e_mail || '');
        const shipPhone = (deliveryAddressChecked.phone || userChecked.phone || '');
        const finalOrder = orderDetails.final_order != null ? orderDetails.final_order : {};
        const isSelling = isTradeSell(orderDetails.trade);
        const hasProforma = orderDetails.proforma_invoices != null && Array.isArray(orderDetails.proforma_invoices) && orderDetails.proforma_invoices.length > 0;
        const proformaAmount = hasProforma ? orderDetails.proforma_invoices.map(p => Number(p.sum_brutto)).reduce((sum, val) => sum + val, 0.0) : 0.0;
        
        return <div className="d-flex flex-wrap justify-content-center">
            <RightsWrapper from={RIGHTS.MARKETING}>
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Organizácia: </Trans>,
                            value: <React.Fragment>
                                <RightsWrapper from={RIGHTS.WORKER} to={RIGHTS.WORKER}>
                                    <CustomerLink
                                        customerId={customerChecked.id}
                                        customerName={customerChecked.name}
                                    />
                                </RightsWrapper>
                                <RightsWrapper deny={[RIGHTS.WORKER]}>
                                    { editingCustomer ? 
                                        <CustomersSelect
                                            value={customerChecked.id}
                                            onChange={this.onCustomerChange}
                                        /> : 
                                        <div className="d-flex align-items-center">
                                            <CustomerLink
                                                customerId={customerChecked.id}
                                                customerName={customerChecked.name}
                                            />
                                            <Button 
                                                size="small" 
                                                icon="edit"
                                                className="ml-2"
                                                onClick={() => this.setState({editingCustomer: true})}
                                            />
                                        </div>
                                    }
                                </RightsWrapper>
                            </React.Fragment>
                        },
                        {
                            title: <Trans>Cenová hladina: </Trans>,
                            value: editingPriceLevel ? 
                                <ChildPriceLevelSelect
                                    customerId={customerChecked.id}
                                    value={orderDetails.id_price_level}
                                    onChange={this.onPriceLevelChange}
                                /> : 
                                <div className="d-flex align-items-center">
                                    { orderDetails.id_price_level != null ?
                                        <CustomerLink
                                            customerId={orderDetails.id_price_level}
                                            customerName={orderDetails.price_level}
                                        />
                                        :
                                        '-'
                                    }
                                    <Button 
                                        size="small" 
                                        icon="edit"
                                        className="ml-2"
                                        onClick={() => this.setState({editingPriceLevel: true})}
                                    />
                                </div>
                        },
                        {
                            title: <Trans>IČO: </Trans>,
                            value: formatString(customerChecked.ico)
                        },
                        {
                            title: <Trans>IČ DPH: </Trans>,
                            value: formatString(customerChecked.ic_dph)
                        },
                        {
                            title: <Trans>Zodpovedný obchodník: </Trans>,
                            value: customerChecked.salesman
                        },
                        {
                            title: <Trans>Logista: </Trans>,
                            value: customerChecked.salesman2
                        },
                        {
                            title: <Trans>Vybavuje: </Trans>,
                            value: <EmployeesSelect
                                value={dealerChecked.id}
                                onChange={this.onDealerChange}
                            />
                        },
                        {
                            title: <Trans>Oddelenie: </Trans>,
                            value: editingDepartment ? 
                                <DepartmentsSelect
                                    value={departmentChecked.id}
                                    onChange={this.onDepartmentChange}
                                    allowClear={true}
                                /> : 
                                <div className="d-flex align-items-center">
                                    {formatString(departmentChecked.name)}
                                    <Button 
                                        size="small" 
                                        icon="edit"
                                        className="ml-2"
                                        onClick={() => this.setState({editingDepartment: true})}
                                    />
                                </div>
                        },
                        {
                            title: <Trans>Odoslané: </Trans>,
                            value: <React.Fragment>
                                <RightsWrapper to={RIGHTS.DEALER}>
                                    <Date dateString={orderDetails.sent_at}/>
                                </RightsWrapper>
                                <RightsWrapper from={RIGHTS.SUPERVISOR}>
                                    { editingDate ? 
                                        <DatePicker
                                            value={orderDetails.sent_at}
                                            onChange={this.onSentAtChange}
                                            required={isSent}
                                        /> : 
                                        <div className="d-flex align-items-center">
                                            <Date dateString={orderDetails.sent_at}/>
                                            <Button 
                                                size="small" 
                                                icon="edit"
                                                className="ml-2"
                                                onClick={() => this.setState({editingDate: true})}
                                            />
                                        </div>
                                    }
                                </RightsWrapper>
                            </React.Fragment>
                        },
                        {
                            title: <Trans>Posledná zmena: </Trans>,
                            value: <DateTime timeString={orderDetails.last_change}/>
                        },
                        {
                            value: <ChannelOptionsDisplay 
                                name={customerChecked.channel} 
                                options={orderDetails.options} 
                                args={orderDetails.args} 
                                onEdit={this.editOrder}
                            />
                        }
                    ]}
                />
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Kontakt: </Trans>,
                            value: (!isSent && orderDetails.is_external == 0) || editingUser ? 
                                <UserSelectByCustomer
                                    customerId={customerChecked.id}
                                    value={userChecked.id}
                                    onChange={this.onUserChange}
                                    allowClear={true}
                                /> :
                                <React.Fragment>
                                    <RightsWrapper from={RIGHTS.WORKER} to={RIGHTS.WORKER}>
                                        <UserLink
                                            userId={userChecked.id}
                                            userName={userChecked.fullname}
                                        />
                                    </RightsWrapper>
                                    <RightsWrapper deny={[RIGHTS.WORKER]}>
                                        <div className="d-flex align-items-center">
                                            <UserLink
                                                userId={userChecked.id}
                                                userName={userChecked.fullname}
                                            />
                                            <Button 
                                                size="small" 
                                                icon="edit"
                                                className="ml-2"
                                                onClick={() => this.setState({editingUser: true})}
                                            />
                                        </div>
                                    </RightsWrapper>
                                </React.Fragment> 
                        },
                        {
                            title: <Trans>Email: </Trans>,
                            value: <EmailLink email={userChecked.e_mail}/>
                        },
                        {
                            title: <Trans>Telefón: </Trans>,
                            value: <PhoneLink phone={userChecked.phone}/>
                        },
                        {
                            title: isSelling || orderDetails.is_remote == 0 ? <Trans>Koncový zákazník:</Trans> : <Trans>Dodávateľ:</Trans>,
                            value: <div className="d-flex align-items-center">
                                <div>
                                    { finalOrder.customer != null ? 
                                        <div>{finalOrder.customer.name}</div>
                                        : null
                                    }
                                    { finalOrder.order_reference != null ? 
                                        <div className="font-italic">
                                            <Trans>Č. obj.:</Trans>
                                            <span className="ml-2">{finalOrder.order_reference}</span>
                                        </div>
                                        : null
                                    }
                                </div>
                                <EditFinalOrder
                                    orderDetails={orderDetails}
                                    className="ml-2"
                                />
                            </div>
                        },
                        {
                            title: <Trans>Dodacia adresa: </Trans>,
                            value: <DeliveryAddress
                                value={deliveryAddressChecked.id}
                                currentOption={orderDetails.delivery_address}
                                onChange={this.onDeliveryAddressChange}
                                onPickupPointChange={this.onPickupPointChange}
                                isSelling={isSelling}
                                isProxy={orderDetails.final_order != null}
                                transportType={orderDetails.transport_type}
                                customerAddresses={customerAddresses}
                            />
                        },
                        {
                            title: <Trans>Tovar preberá: </Trans>,
                            value: <div className="d-flex align-items-center">
                                <div className="pr-2">
                                    <div>{formatString(shipUser)}</div>
                                    <div className="font-italic">{shipEmail + (shipEmail != '' && shipPhone != '' ? ', ' : '') + shipPhone}</div>
                                </div>
                                <EditAddress
                                    address={deliveryAddressChecked} 
                                    onFinishSuccessful={this.reloadOrder} 
                                    userId={deliveryAddressChecked.id_user} 
                                    customerId={deliveryAddressChecked.id_customer}
                                    contactOnly={true}
                                />
                            </div>
                        },
                        {
                            title: <Trans>Fakturačná adresa: </Trans>,
                            value: isSelling ?
                                <AddressSelectByCustomer
                                    customerId={customerChecked.id}
                                    userId={userChecked.id}
                                    value={invoiceAddressChecked.id}
                                    currentOption={invoiceAddressChecked}
                                    onChange={this.onInvoiceAddressChange}                        
                                /> :
                                <AddressSelect
                                    addresses={accountAddresses}
                                    value={invoiceAddressChecked.id}
                                    currentOption={invoiceAddressChecked}
                                    onChange={this.onInvoiceAddressChange}
                                />
                        },
                        {
                            title: <Trans>Spôsob dopravy: </Trans>,
                            value: <TransportTypeSelect
                                onChange={this.onTransportTypeChange}
                                value={transportType.id}
                                allowClear={false}
                                displayPrice={false}
                            />
                        },
                        {
                            title: <Trans>Cena dopravy:</Trans>,
                            value: <div className="d-flex">
                                <InputPrice
                                    className="text-right"
                                    onChange={this.onTransportPriceChange}
                                    value={transportType.price}
                                    withVAT={false}
                                    currency={orderDetails.id_currency}
                                />
                                <InputPrice
                                    disabled={true}
                                    wrapperClassName="ml-2"
                                    className="text-right"
                                    value={transportType.price_brutto}
                                    withVAT={true}
                                    currency={orderDetails.id_currency}
                                />
                            </div>

                        },
                        {
                            title: <Trans>Spôsob platby: </Trans>,
                            value: <PaymentMethodSelect
                                onChange={this.onPaymentMethodChange}
                                value={paymentMethod.id}
                                allowClear={false}
                                displayPrice={false}
                                disabled={paymentMethod.id == PAYMENT_METHOD.PROFORMA_INVOICE && hasProforma}
                            />
                        },
                        {
                            title: <Trans>Cena platby: </Trans>,
                            value: <div className="d-flex">
                                <InputPrice
                                    className="text-right"
                                    onChange={this.onPaymentPriceChange}
                                    value={paymentMethod.price}
                                    withVAT={false}
                                    currency={orderDetails.id_currency}
                                />
                                <InputPrice
                                    disabled={true}
                                    className="text-right"
                                    wrapperClassName="ml-2"
                                    value={paymentMethod.price_brutto}
                                    withVAT={true}
                                    currency={orderDetails.id_currency}
                                />
                            </div>
                        },
                        {
                            title: <Trans>Zľavový kód: </Trans>,
                            value: orderDetails.voucher_code,
                            isHidden: (orderDetails.voucher_code == null)
                        }
                    ]}
                />
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Číslo objednávky u protistrany:</Trans>,
                            value: <InputText
                                onChange={this.onCustomerReferenceChange}
                                value={orderDetails.customer_reference}
                                suffix={ orderDetails.customer_reference_duplicity ? 
                                    <Tooltip title={<Trans>Zadané číslo objednávky už existuje</Trans>}>
                                        <Icon type="warning" theme="twoTone" twoToneColor="#ffbf00"/>
                                    </Tooltip> : null
                                }
                            />
                        },
                        {
                            title: <Trans>Zákazka:</Trans>,
                            value: <DealCodeSelect
                                customerId={customerChecked.id}
                                value={orderDetails.deal_code}
                                onChange={this.onDealCodeChange}
                            />
                        },
                        {
                            isHidden: !hasProforma && (
                                !isSelling || !isSent || paymentMethod.id != PAYMENT_METHOD.PROFORMA_INVOICE
                            ),
                            title: <Trans>Predfaktúra:</Trans>,
                            value: <div>
                                { hasProforma ?
                                    <div> 
                                        { orderDetails.proforma_invoices.map((proforma, idx) => {
                                            return <a 
                                                key={idx}
                                                target="_blank" 
                                                href={addQueryParamsToUrl(STOCK_ROOT + STOCK_ROUTES.INVOICE_DETAILS, {id: proforma.id})} 
                                                className="px-2 text-dark"
                                            >
                                                {proforma.dealer_reference}
                                            </a>
                                        })}
                                    </div>
                                    : null
                                }
                                { Number(orderDetails.sum_brutto) > proformaAmount ? 
                                    <MakeProformaInvoice orderDetails={orderDetails}/>
                                    : null
                                }
                            </div>
                        },
                        {
                            title: <Trans>Odoslanie po častiach: </Trans>,
                            value: isSelling || !isSent ? 
                                <Radio.Group
                                    value={orderDetails.is_divisible}
                                    onChange={(e) => {this.onIsDivisibleChange(getInputOnChangeEventValue(e))}}
                                >
                                    <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                                    <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                                </Radio.Group>
                                :
                                <YesNo value={orderDetails.is_divisible}/>
                        },
                        {
                            title: <Trans>Zaplatené:</Trans>,
                            value: formatPrice(orderDetails.prepayment, orderDetails.id_currency),
                            isHidden: !isSelling
                        },
                        {
                            title: <Trans>Vyskladniť zo skladov: </Trans>,
                            value: <StocksSelect
                                isMulti={true}
                                value={orderDetails.id_stock}
                                onChange={this.onStockChange}
                            />,
                            isHidden: !isSelling
                        },
                        {
                            title: <Trans>Prijať na sklad: </Trans>,
                            value: isOrderRemote(orderDetails) ? 
                            orderDetails.dst_stock : 
                            <StocksSelect
                                value={orderDetails.id_dst_stock}
                                onChange={this.onDstStockChange}
                                allowClear={true}
                            />,
                        },
                        {
                            title: <Trans>Typ pohybu: </Trans>,
                            value: <TransferTypesSelect
                                trade={orderDetails.trade}
                                value={orderDetails.id_transfer_type}
                                onChange={this.onTransferTypeChange}
                            />,
                        },
                        {
                            title: <Trans>Poznámka: </Trans>,
                            value: <InputTextArea
                                value={orderDetails.customer_notices}
                                onChange={this.onCustomerNoticesChange}
                            />,
                        },
                        {
                            title: <Trans>Interná poznámka: </Trans>,
                            value: <InputTextArea
                                value={orderDetails.dealer_notices}
                                onChange={this.onDealerNoticesChange}
                            />,
                        },
                        {
                            title: <Trans>Vytvoril: </Trans>,
                            value: orderDetails.creator,
                        },
                        {
                            title: <Trans>Mena:</Trans>,
                            value: <OrderCurrency
                                orderDetails={orderDetails}
                                editOrder={this.editOrder}
                                disabled={isOrderFinished(orderDetails)}
                            />
                        }
                    ]}
                />
            </RightsWrapper>
            <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Predajca: </Trans>,
                            value: dealerChecked.fullname
                        },
                        {
                            title: <Trans>Email: </Trans>,
                            value: <EmailLink email={dealerChecked.e_mail}/>
                        },
                        {
                            title: <Trans>Telefón: </Trans>,
                            value: <PhoneLink phone={dealerChecked.phone}/>
                        },
                        {
                            title: <Trans>Odoslané: </Trans>,
                            value: <DateTime timeString={orderDetails.sent_at}/>
                        },
                        {
                            title: <Trans>Cenová hladina: </Trans>,
                            value: orderDetails.price_level,
                            isHidden: (orderDetails.price_level == null)
                        }
                    ]}
                />
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Vaše číslo objednávky:</Trans>,
                            value: <h4>{formatString(orderDetails.customer_reference)}</h4>
                        },
                        {
                            title: isSelling ? <Trans>Koncový zákazník:</Trans> : <Trans>Dodávateľ:</Trans>,
                            value: <div className="d-flex align-items-center">
                                <div>
                                    { finalOrder.customer != null ? 
                                        <div>{finalOrder.customer.name}</div>
                                        : null
                                    }
                                    { finalOrder.order_reference != null ? 
                                        <div className="font-italic">
                                            <Trans>Č. obj.:</Trans>
                                            <span className="ml-2">{finalOrder.order_reference}</span>
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>,
                            isHidden: finalOrder.customer == null
                        },
                        {
                            title: <Trans>Dodacia adresa: </Trans>,
                            value: <Address address={orderDetails.delivery_address}/>
                        },
                        {
                            title: <Trans>Tovar preberá: </Trans>,
                            value: <div>
                                <div>{formatString(shipUser)}</div>
                                <div className="font-italic">{shipEmail + (shipEmail != '' && shipPhone != '' ? ', ' : '') + shipPhone}</div>
                            </div> 
                        },
                        {
                            title: <Trans>Fakturačná adresa: </Trans>,
                            value: <Address address={orderDetails.invoice_address}/>
                        },
                        {
                            title: <Trans>Odoslanie po častiach: </Trans>,
                            value: <YesNo
                                value={orderDetails.is_divisible}
                            />
                        },
                        {
                            title: <Trans>Zľavový kód: </Trans>,
                            value: orderDetails.voucher_code,
                            isHidden: (orderDetails.voucher_code == null)
                        }
                    ]}
                />
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Zákazka:</Trans>,
                            value: orderDetails.deal_code
                        },
                        {
                            title: <Trans>Spôsob dopravy: </Trans>,
                            value: <NamePrice namePrice={orderDetails.transport_type}/>
                        },
                        {
                            title: <Trans>Spôsob platby: </Trans>,
                            value: <NamePrice namePrice={orderDetails.payment_method}/>
                        },
                        {
                            title: <Trans>Zaplatené:</Trans>,
                            value: <div className="d-flex align-items-center flex-wrap">
                                {formatPrice(orderDetails.prepayment, orderDetails.id_currency)}
                                {wholePrice != null && prepaymentNumber < wholePrice ?
                                    <PayRest orderDetails={orderDetails} className="ml-2"/> :
                                    null
                                }
                            </div>,
                        },
                        {
                            title: <Trans>Poznámka: </Trans>,
                            value: <pre>{orderDetails.customer_notices}</pre>
                        },
                        {
                            title: <Trans>Vytvoril: </Trans>,
                            value: orderDetails.creator,
                        },
                    ]}
                />
            </RightsWrapper>
        </div>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.RIGHTS, SESSION_ATTRIBUTES.SETTINGS])(
    withDataHOC([
        GLOBAL_DATA.ACCOUNT_ADDRESSES,
        GLOBAL_DATA.COMPANY_ADDRESSES,
        GLOBAL_DATA.RELOAD_DATA, 
        GLOBAL_DATA.FETCH_HANDLER
    ])(OrderDetailsInfo));