import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import AddressForm from './form/AddressForm';
import Tooltip from '../../general/Tooltip';
import generalFailedPC from '../../fetch/generalFailedPC';
import {UserAddress} from '../../../constants/propTypesDefinitions';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
/**
 * @fero
 */

class EditAddress extends React.PureComponent {
    static propTypes = {
        userId: PropTypes.number,
        customerId: PropTypes.number,
        address:  UserAddress,
        userAddresses: PropTypes.arrayOf(UserAddress),
        onFinishSuccessful: PropTypes.func,
        contactOnly: PropTypes.bool,
    };

    static defaultProps = {
        contactOnly: false
    };

    render() {
        const {userId, customerId, onFinishSuccessful, address, userAddresses, contactOnly, ...props} = this.props;
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Upraviť adresu</Trans>}>
                    <Button 
                        icon="edit" 
                        size="small" 
                        {...props}
                    />
                </Tooltip>
            }
            values={{userId, customerId, isEdit: true, contactOnly, address, userAddresses}}
            onFinishSuccessful={onFinishSuccessful}
            title={<Trans>Úprava adresy</Trans>}
            Form={AddressForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť adresu.`)}
        />;
    }
}

export default EditAddress;