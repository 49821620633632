import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import locationHOC from '../../locationProvider/locationHOC';
import FormModal from '../../fetch/FormModal';
import {getQueryParam} from '../../../lib/url';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {GLOBAL_DATA} from '../../../constants/globalData';
import generalFailedPC from '../../fetch/generalFailedPC';
import {OrderDetail, Trade} from '../../../constants/propTypesDefinitions';
import {Button, Form, InputNumber} from 'antd';
import {Trans, t} from '@lingui/macro';
import { createFetchInvoiceAddProforma } from '../../../backend/apiCalls';
import DatePicker from '../../general/DatePicker';
import { currentDate, dateAdd } from '../../../lib/date';
import InvoiceTypeSelect from '../../project/InvoiceTypeSelect';
import { formItemLayout, tailFormItemLayout, mandatoryRule } from '../../../constants/form';
import { INVOICE_CLASS } from '../../../constants/invoiceClasses';
const FormItem = Form.Item;

/**
 * @dusan
 */


class MakeProformaInvoice extends React.PureComponent {
    static propTypes = {
        orderDetails: OrderDetail.isRequired,
    };

    reloadOrder = () => {
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        reload([GLOBAL_DATA.ORDER_DETAILS]);
    };

    getResponse = () => {
        return <div>
            <h4>{t`Predfaktúra bola úspešne vytvorená`}</h4>
        </div>;
    };

    render() {
        const {location, orderDetails} = this.props;
        const orderId = getQueryParam(location, QUERY_PARAMS.ID_ORDER);
        const orderDetailsChecked = orderDetails != null ? orderDetails : {};
        return <FormModal
            openNode={
                <Button type="primary">
                    <Trans>Vytvoriť</Trans>
                </Button>
            }
            values={{orderId: orderId, trade: orderDetailsChecked.trade}}
            onFinishSuccessful={this.reloadOrder}
            title={<Trans>Vytvorenie predfaktúry</Trans>}
            Form={MakeInvoiceForm}
            Response={this.getResponse}
            Failed={generalFailedPC(t`Nepodarilo sa vytvoriť a odoslať predfaktúru.`)}
        />;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.RELOAD_DATA])(MakeProformaInvoice));

class MakeProformaInvoiceForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            orderId: PropTypes.number.isRequired,
            trade: Trade.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    handleSubmit = (e) => {
        const {onSubmitFinish} = this.props;
        
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                createFetchInvoiceAddProforma()(formValues).then((response) => {
                    this.setState({loading: false});
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {onClose, values} = this.props;
        const {orderId, trade} = values;
        const {loading} = this.state;
        const {getFieldDecorator} = this.props.form;
        {getFieldDecorator('id_order', {initialValue: orderId})}
        {getFieldDecorator('send', {initialValue: true})}
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Typ predfaktúry</Trans>}>
                {getFieldDecorator('id_type', {rules:[mandatoryRule]})(
                    <InvoiceTypeSelect
                        trade={trade}
                        invoiceClass={INVOICE_CLASS.PROFORMA}
                    />
                )}
            </FormItem>
            <FormItem 
                {...formItemLayout}
                label={<Trans>Dátum fakturácie</Trans>}
            >
                {getFieldDecorator('invoiced_at', {initialValue: currentDate(), rules:[mandatoryRule]})(
                    <DatePicker required={true}/>
                )}
            </FormItem>
            <FormItem 
                {...formItemLayout}
                label={<Trans>Dátum splatnosti</Trans>}
            >
                {getFieldDecorator('payment_due', {initialValue: dateAdd(currentDate(), 5), rules:[mandatoryRule]})(
                    <DatePicker required={true}/>
                )}
            </FormItem>
            <FormItem 
                {...formItemLayout}
                label={<Trans>Uhradiť čiastku</Trans>}
            >
                {getFieldDecorator('ratio', {initialValue: 1, rules:[mandatoryRule]})(
                    <InputNumber 
                        formatter={val => `${100*Number(val)}%`}
                        parser={val => Number(val.replace('%', '')/100)}
                        step={0.01}
                        allowClear={false}
                    />
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" onClick={this.handleSubmit} loading={loading}>
                    <Trans>Vytvoriť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const MakeInvoiceForm = Form.create()(MakeProformaInvoiceForm);