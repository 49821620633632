import React from 'react';
import {OrderDetailLine} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @dusan
 */

class OrderLinePricelevel extends React.PureComponent {
    static propTypes = {
        orderDetailsLine: OrderDetailLine.isRequired,
    };

    render() {
        const {orderDetailsLine} = this.props;
       
        return orderDetailsLine.price_level != null ? 
        <div className="table-subdata full-size-width text-left">
            <Trans>Cen. hl.:</Trans>
            <span className="ml-2">
                { orderDetailsLine.price_level }
            </span>
        </div>
        : null;
    }
}

export default OrderLinePricelevel;