import React from 'react';
import PropTypes from 'prop-types';
import InputQuantity from '../../project/InputQuantity';
import {formatQuantity, formatQuantityPerPackage} from '../../../lib/formatting';
import {numberOrNull} from '../../../lib/number';
import {Trans, t} from '@lingui/macro';
import {OrderDetailLine, Customer, OrderDetail} from '../../../constants/propTypesDefinitions';
import OrdersModalTable from './OrdersModalTable';
import OrderReservationsModal from './OrderReservationsModal';
import PackageTypeSelect from '../../project/PackageTypeSelect';
import ComponentDetailsModal from '../../project/productTable/explainAvailability/ComponentDetailsModal';
import Tooltip from '../../general/Tooltip';

/**
 * @fero
 */

class OrderItemQuantityForDealer extends React.PureComponent {
    static propTypes = {
        reload: PropTypes.func.isRequired,
        data: OrderDetailLine.isRequired,
        isSelling: PropTypes.bool,
        isSent: PropTypes.bool,
        missingQuantity: PropTypes.number,
        finalOrder: OrderDetail.isRequired,
        onQuantityChange: PropTypes.func.isRequired,
        onPackageTypeChange: PropTypes.func.isRequired,
    };

    render() {
        const {
            data, isSelling, isSent, finalOrder, reload, onQuantityChange, missingQuantity, onPackageTypeChange
        } = this.props;
        return <React.Fragment>
            { data.id_remote_order == null ? 
                <InputQuantity
                    className="text-right"
                    size="small"
                    value={numberOrNull(data.package_quantity)}
                    onChange={onQuantityChange}
                    min={0}
                    unit={
                        <PackageTypeSelect
                            size="small"
                            packageInfo={data}
                            value={data.id_package_type}
                            onChange={onPackageTypeChange}
                        />
                    }
                /> 
                :
                <div className="text-right">{formatQuantity(data.package_quantity, data.package_type)}</div>
            }
            <div className="table-subdata">
                {formatQuantityPerPackage(data.quantity_bulk, data.quantity_units, 'kart')}
            </div>
            <div className="table-subdata">
                {formatQuantityPerPackage(data.quantity_pallet, data.quantity_units, 'pal')}
            </div>
            { data.only_bulk_purchase > 0 && !isSelling ?  
                <div className="color-red table-subdata">
                    <Trans>Min. nákup:</Trans>
                    {' '}
                    {formatQuantity(data.quantity_bulk, data.quantity_units)}
                </div>
                : null
            }
            <div className="table-subdata">
                <Trans>Nedodané: </Trans>
                {' '}
                {formatQuantity(data.quantity_opened, data.quantity_units)}
            </div>
            {isSelling ? <div>
                <div className={"table-subdata" + (Number(data.quantity_on_stock) < Number(data.quantity_opened) ? " color-red" : "")}>
                    <Trans>Skladom: </Trans>
                    {' '}
                    {formatQuantity(data.quantity_on_stock, data.quantity_units)}
                </div>
                <div className="table-subdata">
                    <Trans>Rezervované: </Trans>
                    {' '}
                    {formatQuantity(data.quantity_reserved, data.quantity_units)}
                </div>
                <div className="table-subdata">
                    <Trans>Objednané: </Trans>
                    {' '}
                    {formatQuantity(data.quantity_ordered, data.quantity_units)}
                </div>
                {data.components != null ?
                    <ComponentDetailsModal
                        reload={reload}
                        componentDetails={data.components}
                        productDesignation={data.designation}
                        productManufacturer={data.manufacturer}
                        quantityUnits={data.quantity_units}
                        finalOrder={finalOrder}
                        missingQuantity={missingQuantity}
                    /> : null
                }
                {data.exclusive_reservation != null && Number(data.exclusive_reservation) > 0 ?
                    <div className="table-subdata color-red">
                        <Tooltip title={<Trans>položka nebude vyskladená, kým nepríde celé balenie od dodávateľa</Trans>}>
                            <Trans>Objednané v celku</Trans>
                        </Tooltip>
                    </div>
                    :
                    <OrdersModalTable
                        className="full-size-width"
                        size="small"
                        productId={data.id_product}
                        missingQuantity={missingQuantity}
                        fullQuantity={isSent ? data.quantity_opened : data.quantity}
                        finalOrder={finalOrder}
                        finalOrderItemId={data.id_item}
                        finalOrderAvailableAt={data.available_at}
                        isService={data.is_service}
                        onClose={reload}
                    />
                }
            </div> :
                <OrderReservationsModal
                    className="full-size-width"
                    size="small"
                    orderLine={data}
                    reload={reload}
                />
            }
        </React.Fragment>;
    }
}

export default OrderItemQuantityForDealer;