import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';
import { createFetchInvoiceTypes } from '../../backend/apiCalls';
import { Trade } from '../../constants/propTypesDefinitions';

/**
 * @fero
 */

class InvoiceTypeSelect extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
        trade: Trade.isRequired,
        invoiceClass: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        allowClear: PropTypes.bool,
    };

    static defaultProps = {
        allowClear: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            types: [],
            loading: false,
        };
    }

    fetchInvoiceTypes = () => {
        const {trade, invoiceClass, [GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        const tradeChecked = trade != null ? trade : undefined;
        const invoiceClassChecked = invoiceClass != null ? invoiceClass : undefined;
        this.setState({loading: true});
        fetchHandler(
            createFetchInvoiceTypes(),
            {
                trade: tradeChecked,
                class: invoiceClassChecked,
            },
            (result) => {
                this.setState({
                    types: result,
                    loading: false,
                });
            }
        );
    };

    componentDidMount() {
        this.fetchInvoiceTypes();
    }

    componentDidUpdate(prevProps) {
        const {trade, invoiceClass} = this.props;
        const {trade: prevTrade, invoiceClass: prevClass} = prevProps;
        if(trade != prevTrade || invoiceClass != prevClass)
            this.fetchInvoiceTypes();
    }

    render() {
        const {trade, invoiceClass, [GLOBAL_DATA.FETCH_HANDLER]: fetchHandler, disabled, ...props} = this.props;
        const {types, loading} = this.state;
        return <Select
            className="full-size-width"
            {...props}
            disabled={loading || disabled}
            options={types.map(type => {
                const typeChecked = type != null ? type : {};
                return {
                    label: typeChecked.id + ' - ' + typeChecked.name,
                    value: typeChecked.id,
                }
            })}
            isSearchable={false}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(InvoiceTypeSelect);