import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import AddProductToOrder from './AddProductToOrder';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import ProductModalTable from '../../project/ProductModalTable';
import {RIGHTS} from '../../../constants/Rights';
import {OrderDetail} from '../../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import EditActions from '../../products/table/EditActions';
import Tooltip from '../../general/Tooltip';

/**
 * @fero
 */

class OrderAddProduct extends React.PureComponent {
    static propTypes = {
        orderDetails: OrderDetail.isRequired,
        label: PropTypes.node,
        className: PropTypes.string,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const {orderDetails, label, className, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        const {customer = {}} = orderDetails;
        return <RightsWrapper from={RIGHTS.MARKETING}>
            <ProductModalTable
                className={className}
                customerId={customer.id}
                orderId={orderDetails.id}
                hideGeneric={true}
                modalTitle={<span><Trans>Pridanie produktov do objednávky</Trans></span>}
                MainRowActions={AddProductToOrder}
                InfoRowActions={EditActions}
                onClose={() => {
                    reload([GLOBAL_DATA.ORDER_DETAILS]);
                }}
                openNode={
                    <Tooltip title={<Trans>Pridať produkt do objednávky</Trans>}>
                        <Button
                            size="small"
                            icon="plus"
                            type="primary"
                        >
                            {label}
                        </Button>
                    </Tooltip>
                }
            />
        </RightsWrapper>;
    }
}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(OrderAddProduct);