import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import OrderDetailsActions from './orderDetails/OrderDetailsActions';
import OrderDetailsHeader from './orderDetails/OrderDetailsHeader';
import withDataHOC from '../dataProvider/withDataHOC';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import OrderDetailsTable from './orderDetails/OrderDetailsTable';
import OrderDetailsInfo from './orderDetails/OrderDetailsInfo';
import OrderAddProduct from './orderDetails/OrderAddProduct';
import OrderReceivedModal from './orderDetails/OrderReceivedModal';
import AttachmentsForDealerAndUser from '../project/AttachmentsForDealerAndUser';
import {OrderDetail} from '../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../constants/globalData';
import {Trans, t} from '@lingui/macro';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import { createFetchCustomerAddresses } from '../../backend/apiCalls';
import { RIGHTS } from '../../constants/Rights';

/**
 * @fero
 */

class OrderDetailsPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.ORDER_DETAILS]: OrderDetail.isRequired,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            addresses: []
        };
    }

    componentDidMount() {
        this.fetchAddresses();
    }

    componentDidUpdate(prevProps) {
        const {[GLOBAL_DATA.ORDER_DETAILS]: orderDetails} = this.props;
        const {[GLOBAL_DATA.ORDER_DETAILS]: prevOrderDetails} = prevProps;

        const customerId = orderDetails != null && orderDetails.customer != null ? orderDetails.customer.id : null;
        const userId = orderDetails != null && orderDetails.user != null ? orderDetails.user.id : null;
        const prevCustomerId = prevOrderDetails != null && prevOrderDetails.customer != null ? prevOrderDetails.customer.id : null;
        const prevUserId = prevOrderDetails != null && prevOrderDetails.user != null ? prevOrderDetails.user.id : null;
    
        if(customerId != prevCustomerId || userId != prevUserId)
            this.fetchAddresses();
    }

    fetchAddresses = () => {
        const {
            [GLOBAL_DATA.ORDER_DETAILS]: orderDetails, 
            [GLOBAL_DATA.FETCH_HANDLER]: fetchHandler,
            [SESSION_ATTRIBUTES.RIGHTS]: rights
        } = this.props;
        const customerId = orderDetails != null && orderDetails.customer != null ? orderDetails.customer.id : null;
        const userId = orderDetails != null && orderDetails.user != null ? orderDetails.user.id : null;

        if(customerId != null && rights >= RIGHTS.MARKETING)
        {
            fetchHandler(
                createFetchCustomerAddresses(),
                {id_customer: customerId, id_user: userId},
                (result) => {
                    this.setState({ addresses: result});
                }
            );
        }
        else
        {
            this.setState({addresses: []});
        }
    };

    render() {
        const {
            [GLOBAL_DATA.ORDER_DETAILS]: orderDetails = {},
            [SESSION_ATTRIBUTES.SETTINGS]: settings = {},
            [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile,
        } = this.props;
        const {addresses} = this.state;
        const userChecked = orderDetails != null ? orderDetails.user : null;
        return <React.Fragment>
            <Helmet
                title={`${t`Objednávka`} ${orderDetails.dealer_reference}`}
            >
                { settings.packeta_widget_src != null ? 
                    <script async src={settings.packeta_widget_src}/>
                    : null
                }
            </Helmet>
            <div className="px-3 full-size-height full-size-width overflow-y-auto">
                <OrderDetailsActions
                    orderDetails={orderDetails}
                />
                <OrderDetailsHeader
                    orderDetails={orderDetails}
                />
                <OrderDetailsInfo
                    orderDetails={orderDetails}
                    customerAddresses={addresses}
                />
                {isMobile ? 
                    <OrderAddProduct 
                        orderDetails={orderDetails}
                        label={<Trans>Pridať produkt</Trans>}
                        className="py-2"
                    /> 
                    : 
                    null
                }
                <OrderDetailsTable 
                    orderDetails={orderDetails}
                    customerAddresses={addresses}
                />
                <AttachmentsForDealerAndUser
                    orderId={orderDetails.id}
                    userId={userChecked != null ? userChecked.id : null}
                />
                <OrderReceivedModal
                    orderDetails={orderDetails}
                />
            </div>
        </React.Fragment>;
    }
}

export default withSessionHOC([SESSION_ATTRIBUTES.SETTINGS, SESSION_ATTRIBUTES.RIGHTS, SESSION_ATTRIBUTES.IS_MOBILE])(
    withDataHOC([GLOBAL_DATA.ORDER_DETAILS, GLOBAL_DATA.FETCH_HANDLER])(
        OrderDetailsPage
    )
);