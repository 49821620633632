import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import {RIGHTS} from '../../constants/Rights';
import {Trans, t} from '@lingui/macro'
import Price from '../general/Price';

/**
 * @fero
 */

class TaxedPriceDisplay extends React.PureComponent {
    static propTypes = {
        totalPrice: PropTypes.string,
        linePrice: PropTypes.string
    };

    render() {
        const {totalPrice, linePrice} = this.props;
        return <div>
            <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                <h4>
                    <Price price={totalPrice} withVAT={true}/>
                </h4>
                {'('}
                <Price price={linePrice} withVAT={false}/>
                {')'}
            </RightsWrapper>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <h4>
                    <Price price={linePrice}  withVAT={false}/>
                </h4>
                {'('}
                <Price price={totalPrice} withVAT={true}/>
                {')'}
            </RightsWrapper>
        </div>;
    }

}

export default TaxedPriceDisplay;