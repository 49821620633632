import React from 'react';
import RemoveOrder from '../orders/RemoveOrder';
import SendOrder from './SendOrder';
import DealModal from '../../project/DealModal';
import HistoryModal from '../../history/HistoryModal';
import CopyOrderToCart from './CopyOrderToCart';
import locationHOC from '../../locationProvider/locationHOC';
import {RIGHTS} from '../../../constants/Rights';
import {ROUTES} from '../../../constants/navigation';
import {OrderDetail, Location} from '../../../constants/propTypesDefinitions';
import {navigateToParametrized} from '../../../lib/url';
import {Trans, t} from '@lingui/macro';
import MailPreview from '../../project/MailPreview';
import PdfLink from '../../project/PdfLink';
import PageActionsLayout from '../../general/PageActionsLayout';
import { isOrderSend, isTradeSell } from '../../../lib/project';
import CancelOrder from './CancelOrder';
import DispatchOrder from './DispatchOrder';
import FitOrderPrices from './FitOrderPrices';
import SplitOrder from './SplitOrder';
import OrderFromIncoming from './OrderFromIncoming';


/**
 * @fero
 */

class OrderDetailsActions extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
        orderDetails: OrderDetail.isRequired,
    };

    render() {
        const {orderDetails, location} = this.props;
        const orderId = orderDetails.id;
        return <PageActionsLayout
            back={{
                to: ROUTES.ORDERS,
                title: <Trans>Objednávky</Trans>
            }}
            actions={[
                {
                    rightsFrom: RIGHTS.DEALER,
                    node: <HistoryModal
                        objectId={orderId}
                        objectName={orderDetails.dealer_reference}
                        objectType="order"
                    />
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: <MailPreview 
                        objectType="order" 
                        objectId={orderId}
                    />
                },
                {
                    node: <PdfLink 
                        objectType="order" 
                        objectId={orderId}
                    />
                },
                {
                    node: <DealModal
                        dealPartId={orderId}
                        dealId={orderDetails.id_deal}
                    />
                },
                {
                    node: <CopyOrderToCart
                        orderDetails={orderDetails}
                    />
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: isTradeSell(orderDetails.trade) ?
                        <OrderFromIncoming
                            orderDetails={orderDetails}
                        /> :
                        null
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: <FitOrderPrices
                        orderDetails={orderDetails}
                    />
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: isTradeSell(orderDetails.trade) ? 
                    <SplitOrder
                        orderDetails={orderDetails}
                    /> :
                    null
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: isTradeSell(orderDetails.trade) ? 
                    <DispatchOrder
                        orderDetails={orderDetails}
                    /> :
                    null
                },
                {
                    node: <SendOrder
                        orderDetails={orderDetails}
                    />
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: isOrderSend(orderDetails) ?
                    <CancelOrder
                        orderDetails={orderDetails}
                    /> :
                    <RemoveOrder
                        order={orderDetails}
                        buttonContent={<Trans>Odstrániť</Trans>}
                        onFinishSuccessful={() => {
                            navigateToParametrized(location, ROUTES.ORDERS, {})
                        }}
                    />
                }
            ]}
        />;
    }

}

export default locationHOC(OrderDetailsActions);